
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { PublicRoute } from './routes-type';
import HeaderSkeleton from './components/layouts/header-new/header-skeleton';
import FooterSkeleton from './components/layouts/footer/footer-skeleton';
import LandingPageSkeleton from './pages/landing-page/landing-page-skeleton';

// Lazy-loaded components
const Header = lazy(() => window.innerWidth > 1023
  ? import('./components/layouts/header-new')
  : import('./components/layouts/header-responsive')
);
const Footer = lazy(() => import('./components/layouts/footer'));
const LandingPage = lazy(() => import('./pages/landing-page/landing-page'));
const Overview = lazy(() => import('./pages/overview'));
const KarirPage = lazy(() => import('./pages/karir'));
const JobList = lazy(() => import('./pages/job-list'));
const JobDetail = lazy(() => import('./pages/job-detail'));
const About = lazy(() => import('./pages/about-pages/about'));
const HaloMassage = lazy(() => import('./pages/halo-massage'));
const HaloFix = lazy(() => import('./pages/halo-fix'));
const HaloClean = lazy(() => import('./pages/halo-clean'));
const HaloAuto = lazy(() => import('./pages/halo-auto'));
const RegisterVendor = lazy(() => import('./pages/register-vendor'));

// Webview components
const WebviewComponents = {
  User: lazy(() => import('./pages/webview/webview-user/webview-help')),
  UserSub: lazy(() => import('./pages/webview/webview-user/webview-help-sub')),
  UserDetail: lazy(() => import('./pages/webview/webview-user/webview-help-detail')),
  UserPoint: lazy(() => import('./pages/webview/webview-user/webview-help-point')),
  Vendor: lazy(() => import('./pages/webview/webview-vendor/vendor-help')),
  VendorSub: lazy(() => import('./pages/webview/webview-vendor/vendor-help-sub')),
  VendorDetail: lazy(() => import('./pages/webview/webview-vendor/vendor-help-detail')),
  VendorFeedback: lazy(() => import('./pages/webview/webview-vendor/vendor-help-feedback')),
};

// Initialize Facebook Pixel
if (navigator.userAgent !== 'ReactSnap') {
  ReactPixel.init('455968225269752');
  ReactPixel.pageView();
}

const App = () => {
  return (
    <Router>
      <Suspense fallback={<HeaderSkeleton />}>
        <Route component={Header} />
      </Suspense>

      <Suspense fallback={<LandingPageSkeleton />}>
        <Switch>
          <PublicRoute exact path="/" restricted component={LandingPage} />
          <PublicRoute exact path="/overview/" restricted component={Overview} />
          <PublicRoute exact path="/karir/all/" restricted component={KarirPage} />
          <PublicRoute exact path="/about/job-list/" restricted component={JobList} />

          {/* Job Detail Routes */}
          {[
            'lead-programmers', 'senior-programmers', 'marketing-strategy', 'dev-ops',
            'back-end-nodejs', 'frontend-programmer', 'cto', 'product-manager',
            'system-analyst', 'social-media-strategy', 'system-admin', 'seo',
            'sem', 'sales-marketing', 'sales-marketing-manager', 'qa', 'qc',
            'pr', 'operational-manager', 'motion-graphic', 'mobile-progammer',
            'laravel-progammer', 'design-illustrator', 'digital-marketing',
            'content-specialist', 'data-analyst-specialist', 'business-analyst',
            'android-dev', 'admin-operational'
          ].map(job => (
            <PublicRoute
              key={job}
              exact
              path={`/about/job-detail/${job}`}
              restricted
              component={JobDetail}
            />
          ))}

          <PublicRoute exact path="/reflexology-massage-pijat-panggilan/" restricted component={HaloMassage} />
          <PublicRoute path="/bantuan/tentang-kami/" exact component={About} />
          <PublicRoute exact path="/service-ac-jakarta/" restricted component={HaloFix} />
          <PublicRoute exact path="/jasa-cleaning-service/" restricted component={HaloClean} />
          <PublicRoute exact path="/salon-mobil-pangilan/" restricted component={HaloAuto} />

          {/* Webview Routes */}
          <Route exact path="/webview-user/help-bantuan/" restricted component={WebviewComponents.User} />
          <Route exact path="/webview-user/help-sub/" restricted component={WebviewComponents.UserSub} />
          <Route exact path="/webview-user-detail/" restricted component={WebviewComponents.UserDetail} />
          <Route exact path="/webview-user/help-point/" restricted component={WebviewComponents.UserPoint} />
          <Route exact path="/webview-vendor/help-bantuan/" restricted component={WebviewComponents.Vendor} />
          <Route exact path="/webview-vendor/help-sub/" restricted component={WebviewComponents.VendorSub} />
          <Route exact path="/webview-vendor-detail/" restricted component={WebviewComponents.VendorDetail} />
          <Route exact path="/webview-vendor-feedback/" restricted component={WebviewComponents.VendorFeedback} />

          {/* RegisterVendor Routes */}
          {['/register-vendor/', '/register-vendor/1', '/register-vendor/2', '/register-vendor/3', '/register-vendor/4'].map(path => (
            <Route key={path} exact path={path} restricted component={RegisterVendor} />
          ))}

          {/* Redirect Routes */}
          {['/vendor/register', '/vendor/register/1', '/vendor/register/2', '/vendor/register/3', '/vendor/register/4', '/vendor/register/5'].map(path => (
            <PublicRoute key={path} exact path={path} restricted component={() => {
              window.location.href = `https://halojasa.co.id${path}`;
              return null;
            }} />
          ))}

          <PublicRoute exact path="/service/halo-clean/" restricted component={() => {
            window.location.href = 'https://halojasa.com/jasa-cleaning-service';
            return null;
          }} />
          <PublicRoute exact path="/service/halo-auto/" restricted component={() => {
            window.location.href = 'https://halojasa.com/salon-mobil-pangilan';
            return null;
          }} />
          <PublicRoute exact path="/service/halo-fix/" restricted component={() => {
            window.location.href = 'https://halojasa.com/service-ac-jakarta';
            return null;
          }} />

          {/* Catch-all route for 404 */}
          <Route path="*" component={() => <div>404 Not Found</div>} />
        </Switch>
      </Suspense>

      <Suspense fallback={<FooterSkeleton />}>
        <Route component={Footer} />
      </Suspense>
    </Router>
  );
};

export default App;